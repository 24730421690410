import React from 'react'
import { graphql } from 'gatsby';
import Layout from '@components/layout'
import CaseStudiesMore from '@components/case-studies-more'
import FacebookShare from '@components/fb-share-button';
import TwitterShare from '@components/tw-share-button';

export default function supremeGolf(props) {
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = '/images/supremegolf-case1.jpg'
  const thumbAltText = 'supremegolf case study'
  const description = 'Our partnership with Supreme Golf began a couple of years ago, right around 2015. Since the beginning, our team has been focused on helping Supreme Golf grow and maintain its platform, not only from a technical standpoint, but also from an engineering processes point of view.'
  const shareUrl = siteUrl + props.location.pathname
  const caseTitle = 'WyeWorks helped Supreme Golf improve their engineering culture, to enhance quality and value delivery to their customers.'

  return (
    <Layout
      location={props.location}
      title='Supreme Golf'
      namespace='page'
      description={description}
      thumbnail={thumbnail}
    >
      <div className='hero hero-2'>
        <div className='hero-2__background'></div>
        <div className='hero-2__container'>
          <div className='case-hero__wrapper'>
            <div className='case-hero__pattern'>
              <img src='/images/case-hero-pattern.png' alt='' />
            </div>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Case Study
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='case-hero__hero-block'>
              <div className='case-hero__hero-block-wrapper'>
                <div className='case-hero__case-logo'>
                  <img src='/images/brands/supremegolf-white-brand.png' alt='' />
                </div>
                <h3 className='case-hero__case-title'>
                  {caseTitle}
                </h3>
                <p className='case-hero__description'>
                  {description}
                </p>
              </div>
              <div className='case-hero__hero-block-background'>
                <div className='case-hero__hero-block-gradient'></div>
                <img src='/images/supremegolf-case-card-tall-bg.jpg' alt='' />
              </div>
            </div>
            <div className='case-hero__case-meta'>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Services Used
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Backend Development
                </li>
                <li className='case-meta__list-item'>
                  Frontend Development
                </li>
                <li className='case-meta__list-item'>
                  Process Consulting
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Industry
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  E-Commerce, Sports, Travel
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Timeline
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  3+ Years
                </li>
              </ul>
              <div className='social-layout-1'>
                <FacebookShare url={shareUrl} />
                <TwitterShare url={shareUrl} text={caseTitle.replace('WyeWorks', '@WyeWorks')} />
                <div className='social-layout-1__seperator'></div>
                Share This Case
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='long-content__wrapper'>
          <p>
            When it comes to Golf, it’s common for players around the world to schedule specific time slots in their favorite courses, as a means to practice their favorite sport. Each of these available time slot offerings are commonly referred to as “tee times”.
          </p>
          <p>
            Supreme Golf was born with the goal of making it easy for golfers to compare tee times and prices from thousands of golf courses, online tee time retailers and deal sites at a glance. As mentioned in their site, Supreme Golf at its core is “a technology startup that loves the game and wants to help you play more of it”.
          </p>
          <p>
            In addition to their tee time marketplace solution, the company has developed an award winning course management system, that is being adopted by several golf courses in the US.
          </p>
        </div>
      </section>
      <section className='section--full-right'>
        <div className='image-90__wrapper'>
          <img src={thumbnail} alt={thumbAltText} />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            Our Work
          </h3>
          <p>
            Our partnership with Supreme Golf began a couple of years ago, right around 2015. Since the beginning, our team has been focused on helping Supreme Golf grow and maintain its platform, not only from a technical standpoint, but also from an engineering processes point of view.
          </p>
          <p>
            Through collaboration and teamwork, we’ve helped instill some of the agile and software development practices we firmly believe are capable of enhancing quality and value delivery to our clients.
          </p>
          <p>
            An exhaustive code review mindset helps the development team build top quality solutions. Developer QA as part of the development process provides an additional layer of safety prior to the release of a new feature. All these practices fit under a bi-weekly introspection cycle, which empowers our teams to seek constant improvement under the guidance of one fixed variable: transparency.
          </p>
        </div>
      </section>

      <CaseStudiesMore cases={['aia', 'zozi']} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
